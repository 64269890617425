@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';

%pull-down {
  display: flex;
  align-items: flex-end;
}

// Legacy Styles
.aol-notification-banner {
  background-color: $blue;
  color: $white;
  font-family: var(--font-family-base);
  height: 0;
  margin-left: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  transition: height $speed-slowest $ease;
  width: auto;

  &.banner-shown {
    display: block;
    height: 70px;
    margin-bottom: 30px;

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }

  .flex-wrap {
    display: flex;
    flex-wrap: nowrap;
    height: 70px;
    align-items: center;
  }

  a {
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
    width: 100%;
    align-items: center;
  }

  @media screen and (max-width: 420px) {
    a {
      width: 87%;
    }
  }

  .alert-image {
    color: $white;
    font-size: 50px;
    height: 100%;
    margin-left: 10px;
    width: 10%;

    img {
      height: 50px;
      vertical-align: middle;
      width: 100%;
    }
  }

  .alert-copy {
    color: $white;
    height: 100%;
    margin: 0 10px;
    width: 80%;

    @media screen and (max-width: 1080px) {
      width: 80%;
    }

    .alert-headline {
      display: block;
      font-size: 15px;
      font-weight: 100;
      min-width: 0;
      position: relative;

      p {
        margin: 0;
      }

      > p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 40px;

        @supports (display:-moz-box) {
          display: block;
        }
      }
    }

    .alert-title {
      display: block;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.1em;
      position: relative;
      text-transform: uppercase;
    }
  }

  .alert-close {
    color: $white;
    cursor: pointer;
    height: 100%;
    text-align: center;
    width: 10%;

    &:hover {
      background-color: lighten($blue, 10%);
    }

    .navicon-close {
      img {
        position: relative;
        top: 23px;
      }
    }
  }

  //Breaking Alert overrides
  &.breaking,
  &.high {
    background-color: #eb3d46;

    .alert-button {
      color: #eb3d46;
    }

    .alert-close {
      &:hover {
        background-color: lighten(#eb3d46, 10%);
      }
    }
  }
}

// New Styles
.m-notification-banner {
  align-items: stretch;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  font-family: var(--font-family-heading);
  font-size: 18px;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
  text-align: start;
  text-decoration: none;

  @media screen and (max-width: 600px) {
    font-size: 16px;

    .m-notification-banner__img {
      min-width: 120px;
      width: 20%;
    }

    .m-notification-banner__lbl {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1040px) {

    .m-notification-banner {
      &__lbl {
        font-size: 1.25rem;
        padding: 0.5em 0.5em 0 0;
      }

      &__content {
        padding: 0.5em 0.5em 0 0;
      }
    }
  }

  &__inner {
    flex: 1;
    padding-bottom: 8px;

    @media screen and (min-width: 1040px) {
      display: flex;
      flex-flow: row wrap;
      padding-left: 12px;
    }
  }

  &__lbl {
    @extend %pull-down;
    font-weight: bold;
    flex: 0 0 100%;
    max-width: 100%;

    &--txt {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 22px;
      letter-spacing: -0.2px;
      color: $red;

      @media screen and (min-width: 1040px) {
        font-size: 1.375rem;
      }
    }
  }

  &__content {
    @extend %pull-down;
    align-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-right: 18px;

    p {
      margin-right: 6px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
      color: #232A31;

      @media screen and (min-width: 1040px) {
        font-size: 20px;
      }

    }
  }

  &__title {
    font-weight: bold;

    // LineClamp to 3 lines
    -webkit-line-clamp: 3;
    margin: 0;
    max-height: 4em;

    @supports (display: -moz-box) {
      display: block;
    }
    // End of LineClamp
  }

  &__divider {
    height: 4px;
    border-radius: 2px;
    width: 100%;
    flex: 0 0 100%;
    margin-top: 12px;

    &--none {
      background-color: $purple;
    }

    &--low {
      background-color: $blue;
    }

    &--medium {
      background-color: $yellow;
    }

    &--high {
      background-color: $red;
    }
  }

  &__img {
    align-items: flex-end;
    display: none;

    @media screen and (min-width: 1040px) {
      display: flex;
    }

    img {
      flex: 0 0 100%;
      max-width: 134px;
      height: auto;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &__more-lbl {
    @extend %pull-down;
    font-size: 12px;
    color: #232A31;
    font-weight: 600;

    @media screen and (max-width: 1039px) {
      display: none;
    }
  }

  &--mailToday {
    .m-notification-banner {
      &__content {
        p {
          @include variableColor(--text-base, $batcave);
        }
      }

      &__more-lbl {
        @include variableColor(--text-base, $batcave);
      }
    }
  }
}
